import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import SkuCard from './SkuCard'
import { ProductList } from "../../styledComponents/productList"

const SkusCoachingProgram = (props) => {
  const { prices } = useStaticQuery(graphql`
    query SkuCoachingQuery {
      prices: 
      allStripePrice(filter: {active: {eq: true}, currency: {eq: "gbp"}, product: {active: {eq: true}, metadata: {type: {eq: "distant-healing"}}}}) {
        edges {
          node {
            id
            active
            currency
            unit_amount
            product {
              id
              name
              active
              metadata {
                type
              }
            }
          }
        }
      }
    }
  `)

  return (
    <ProductList>
      {prices.edges.map(({ node: price }) => {
        const newSku = {
          sku: price.id,
          name: price.product.name,
          price: price.unit_amount,
          description: price.product.description,
          currency: price.currency,
          type: price.product.metadata.type
        }
        return <SkuCard key={price.id} sku={newSku}/>
      })}
    </ProductList>
  )
}

export default SkusCoachingProgram
