import React, { useState, useLayoutEffect } from 'react'
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import { EventListItem, EventPrice } from "../../styledComponents/eventsList"
import { StyledAddToCartButton } from "../../styledComponents/button"

const SkuCard = ({ sku }) => {
  const [isFullyBooked, setIsFullyBooked] = useState(false) // manage is success message state
  const { addItem } = useShoppingCart()

  const bookingstatus = sku.bookingstatus
  
  const checkIfAvailable = () => {
    if(bookingstatus === 'fullybooked') {
      setIsFullyBooked(true)
    }
  } 

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      checkIfAvailable()
      return
    }
  }, [])
 
  return (
    <EventListItem>
      <h3>{isFullyBooked && <span className="fully-booked">Fully booked </span>} {sku.name}</h3>
      {!isFullyBooked && sku.dates && <p className="event-dates">Then on the following: <strong>{sku.dates}</strong></p>}
      {sku.description && <p className="product-description">{sku.description}</p>}
      {sku.image && <img src={sku.image} />}
      <EventPrice>
        <strong>{sku.discount && 'Earlybird '}Price: </strong>
        {formatCurrencyString({
          value: parseInt(sku.price),
          currency: sku.currency,
        })}
        {sku.discount &&  <span> save {sku.discount}%</span>}
      </EventPrice>
      <StyledAddToCartButton 
        // disabled={() => checkIfAvailable()}
        disabled={isFullyBooked}
        onClick={() => addItem(sku)}
      >
        Add to cart
      </StyledAddToCartButton>
    </EventListItem>
  )
}

export default SkuCard